import { useContext, useEffect, useState } from "react";
import { StoreContext } from "@context/store.context";
import { scrollTo } from "@lib/scroll";
import FormBuilderProvider from "@context/formbuilder.context";
import Image from "next/image";
import Button from "@components/elements/button.component";
import styles from "@styles/components/homepage/popular-subscriptions.module.scss";
import { formatPrice } from "@lib/product-utilities";
import { findCode } from "./promo-bar.component";
import type { PromoProps } from "@src/types/promo.type";
import * as ProductTypes from "../types/product.type";

const LitterTypes = [
  {
    id: "corn",
    name: "Corn & Wheat",
  },
  {
    id: "clay",
    name: "Clay",
  },
  {
    id: "organic",
    name: "Organic Soy",
  },
  {
    id: "fine-grain",
    name: "Fine-Grain Silica",
  },
  {
    id: "silica",
    name: "Silica",
  },
  {
    id: "only",
    name: "No Litter",
  },
];

type Product = {
  variants: Array<any>;
  pid: string;
  productType: string;
  tiers?: any;
  litterText?: string;
  title: string;
  sellingPlan: string;
};

type PriceOptions = {
  accessoryPrices: Array<number>;
  mainProductPrice: number;
  totalPrice: {
    discountedPrice?: string;
    preDiscountPrice?: string;
  };
};

interface BundleProps {
  collection: {
    handle: string;
    products: any;
    image: any;
    title: string;
    description: string;
  };
  productList: any;
}
interface PageProps {
  data: {
    headline: string;
    subline: string;
    bundles: string[];
    bundle_images: string[];
  };
  popularSubsCollection: BundleProps[];
}

const setupBundles = (bundles: Array<BundleProps>) => {
  if (bundles && bundles.length >= 1) {
    const _bundles = bundles
      .filter((col) => col.collection.handle.includes("bundle"))
      .map((bund) => {
        return {
          ...bund,
        };
      });

    const _litter = bundles
      .filter((col) => !col.collection.handle.includes("bundle"))
      .map((collection) => {
        const _products = collection.productList.map((prod: Product) => {
          const _v = prod.variants[0];
          return { ...prod, vid: _v.vid, price: _v.price };
        });
        return {
          ...collection,
          products: _products,
        };
      });
    return { litter: _litter, bundles: _bundles };
  }
  return { litter: [], bundles: [] };
};

const selectGroup = (products: Array<any>, selected: boolean, idx: number) => {
  return {
    name: "Litter_options",
    hideLables: true,
    formGroup: [
      {
        elements: [
          {
            style: "form-flex",
            id: `litter_type_${idx}`,
            type: "select",
            label: "Litter Options",
            value: selected,
            options: products.map((item) => {
              return {
                name: item.litterText,
                value: item.variants[0].vid,
              };
            }),
          },
        ],
      },
    ],
  };
};

const setupBundleOptions = (bundles: Array<BundleProps>) => {
  const bundleOptions: Array<any> = [];

  bundles.forEach((bundle, i: number) => {
    let mainOption = {};
    const optionalAccessories: Array<any> = [];
    const priceOptions: PriceOptions = {
      accessoryPrices: [],
      mainProductPrice: 0,
      totalPrice: {},
    };

    let preDiscountPrice = 0;
    let discountedPrice = 0;

    bundle.productList.forEach((product: Product) => {
      const _data = {
        ...product,
        rid: `${product.pid}_${product.variants[0].vid}`,
        vid: product.variants[0].vid,
        quantity: 1,
        variant: product.variants[0],
        price: +product.variants[0].price,
      };

      if (product.productType.includes("box|")) {
        mainOption = {
          ..._data,
          autoShip: true,
          tiers: product.tiers,
        };

        priceOptions.mainProductPrice = _data.price;
        preDiscountPrice += _data.price;
        discountedPrice += _data.price * 0.7;
      } else {
        optionalAccessories.push({ ..._data, autoShip: false });
        priceOptions.accessoryPrices.push(_data.price);
        preDiscountPrice += _data.price;
        discountedPrice += _data.price;
      }
    });

    priceOptions.totalPrice = {
      discountedPrice: formatPrice(discountedPrice),
      preDiscountPrice: formatPrice(preDiscountPrice),
    };

    bundleOptions.push({
      mainProduct: mainOption,
      optionalAccessories,
      prices: priceOptions,
    });
  });
  return bundleOptions;
};

export default function PopularSubscriptions({
  data,
  popularSubsCollection,
}: PageProps) {
  const { addManyToCart } = useContext(StoreContext)!;
  const { litter, bundles } = setupBundles(popularSubsCollection);
  const [bundleTypes, setBundleTypes] = useState<Array<any>>([]);
  const [promo, setPromo] = useState<any>({} as PromoProps);
  const productOptions = (bundle: BundleProps, idx: number) => {
    let _formOptions = {};
    const _litter = litter[0].products
      .filter(
        (type: Product) =>
          type.productType !== "dome" && type.productType !== "box-only"
      )
      .map((prod: Product) => {
        const _type = prod.productType.split("|");
        const _extras = LitterTypes.find((l) => l.id === _type[1]);
        if (_extras) {
          prod.litterText = _extras.name;
        }
        return prod;
      });
    const _lineItems = bundle.productList.map((p: Product, i: number) => {
      if (p.productType.includes("box|")) {
        _formOptions = selectGroup(_litter, p.variants[0].vid, idx);

        return (
          <li key={i}>Your Litter choice with Disposable Cat Litter Box</li>
        );
      }
      return <li key={i}>{p.title}</li>;
    });
    return (
      <>
        <ul>{_lineItems}</ul>
        <div>
          <FormBuilderProvider
            data={_formOptions}
            editing={true}
            handleChange={handleChange}
          />
        </div>
      </>
    );
  };

  const handleChange = (form: any) => {
    const dropdownIndex = +form.changedKey.split("_").pop();
    const litterType = litter[0].products.find(
      (litterOption: any) => litterOption.vid === form.value
    );

    let newBudleTypes = [...bundleTypes];

    let accessoryPrices = newBudleTypes[
      dropdownIndex
    ].prices.accessoryPrices.reduce((acc: number, cur: number) => acc + cur, 0);
    let newMainProduct = {
      ...litterType,
      rid: `${litterType.pid}_${litterType.variants[0].vid}`,
      vid: litterType.variants[0].vid,
      quantity: 1,
      variant: litterType.variants[0],
      price: +litterType.variants[0].price,
      autoShip: true,
    };

    let newPriceOptions = {
      ...newBudleTypes[dropdownIndex].prices,
      mainProductPrice: newMainProduct.price,
      totalPrice: {
        discountedPrice: formatPrice(
          0.7 * newMainProduct.price + accessoryPrices
        ),
        preDiscountPrice: formatPrice(newMainProduct.price + accessoryPrices),
      },
    };

    newBudleTypes[dropdownIndex] = {
      ...newBudleTypes[dropdownIndex],
      mainProduct: newMainProduct,
      prices: newPriceOptions,
    };

    setBundleTypes(newBudleTypes);
  };

  const addToCart = (desiredBundleIndex: number) => {
    const bundleToAdd = bundleTypes[desiredBundleIndex];
    const lineItems: {
      product: ProductTypes.Product;
      sellingPlanId?: string;
      quantity: number;
    }[] = [
      {
        product: bundleToAdd.mainProduct,
        sellingPlanId: bundleToAdd.mainProduct.sellingPlan,
        quantity: 1,
      },
    ];

    bundleToAdd.optionalAccessories.forEach((accessory: any) => {
      lineItems.push({ product: accessory, quantity: 1 });
    });

    // setCoupon();
    addManyToCart(lineItems, false);
  };

  // useEffect(() => {
  //   if (promobarContent) {
  //     setPromo(findCode(promobarContent, hasOrdered, false));
  //   }
  // }, [promobarContent]);

  useEffect(() => {
    if (bundleTypes.length == 0 && bundles) {
      setBundleTypes(setupBundleOptions(bundles));
    }
  }, [bundles]);

  if (bundleTypes.length == 0) {
    return (
      <div className={styles.popularSubs} id={"mostpopularsubscriptions"}>
        <article className={styles.article}></article>
      </div>
    );
  }

  return (
    <div className={styles.popularSubs} id={"mostpopularsubscriptions"}>
      <article className={styles.article}>
        <h2 className={styles.headline}>{data.headline}</h2>
        <p className={styles.subline}>{data.subline}</p>
        <div className={styles.swipe_wrapper}>
          <button
            onClick={() => scrollTo("left", styles.scrollItems, 300)}
            className={styles.swipe_left}
            aria-label="swipe left"
          >
            <i className="fas fa-chevron-left"></i>
          </button>
          <section className={styles.scrollItems}>
            {bundleTypes &&
              bundles.map((bundle, i) => {
                return (
                  <div id="card_${i}" className={styles.card} key={i}>
                    <div className={`${styles.r_col} ${styles.top}`}>
                      <Image
                        src={bundle.collection.image}
                        alt=""
                        className={styles.bundleImg}
                        width={170}
                        height={170}
                      />
                    </div>
                    <div>
                      <h3
                        dangerouslySetInnerHTML={{
                          __html: bundle.collection.title,
                        }}
                      ></h3>
                      <p className={styles.subline}>
                        <i
                          dangerouslySetInnerHTML={{
                            __html: bundle.collection.description,
                          }}
                        ></i>
                      </p>
                    </div>
                    <div className={styles.inner}>
                      {i > 0 ? (
                        <p>
                          <strong>Included with first order:</strong>
                        </p>
                      ) : (
                        <p>
                          <strong>Includes:</strong>
                        </p>
                      )}
                      {productOptions(bundle, i)}
                    </div>
                    <div className={styles.prices}>
                      <div>
                        <h4>FIRST ORDER:</h4>
                        {promo.discount ? (
                          <>
                            <p className={styles.discount_price}>
                              <strong>
                                $
                                {
                                  bundleTypes[i].prices.totalPrice
                                    .discountedPrice
                                }
                                *
                              </strong>
                            </p>
                            <p className={styles.total_price}>
                              $
                              {
                                bundleTypes[i].prices.totalPrice
                                  .preDiscountPrice
                              }
                            </p>
                          </>
                        ) : (
                          <p className={styles.discount_price}>
                            <strong>
                              $
                              {bundleTypes[i].prices.totalPrice.discountedPrice}
                            </strong>
                          </p>
                        )}
                      </div>
                      <div>
                        <h4>RECURRING COST:</h4>
                        <p
                          className={`${styles.discount_price} ${styles.highlight}`}
                        >
                          <strong>${bundleTypes[i].mainProduct.price}</strong>
                        </p>
                        {i > 0 ? (
                          <p>
                            <i>Litter box & litter only</i>
                          </p>
                        ) : (
                          <p className={styles.hideVisible}>
                            <i>Litter box & litter only</i>
                          </p>
                        )}
                      </div>
                    </div>
                    <div className={styles.r_col}>
                      <Button
                        handleClick={() => {
                          addToCart(i);
                        }}
                        text="Add to Cart"
                        style={{
                          margin: "8px 0",
                        }}
                      />
                      <p className={styles.note}>+ FREE Shipping!</p>
                    </div>
                  </div>
                );
              })}
          </section>
          {promo.productText && (
            <p className="coupon_note">
              <i>*{promo.productText}</i>
            </p>
          )}
          <button
            onClick={() => scrollTo("right", styles.scrollItems, 300)}
            className={styles.swipe_right}
            aria-label="swipe right"
          >
            <i className="fas fa-chevron-right"></i>
          </button>
        </div>
      </article>
    </div>
  );
}
