import { getCollectionsByHandles } from "@services/shopify/collections";
import { BuilderComponent, builder } from "@builder.io/react";
import { getBuilderStaticProps, renderLink } from "@services/builderIO";
import type { InferGetStaticPropsType, GetStaticProps } from "next";

import styles from "@styles/components/homepage/homepage.module.scss";
import "@src/builder-registry";
import PopularSubscriptions from "@src/cms_components/popular-subscriptions";
import builderConfig from "@config/builder";
/// MOVE To BUILDER
import HomepageData from "@src/mock-data/homepage.json";
import SEO from "@lib/seo";

builder.init(builderConfig.apiKey);

type pageData = {
  popularSubsCollection: Array<any>;
};

const getPopularSubs = async (context: any) => {
  let popularSubsCollection = []; // PRESET for if fetch fails page can contiue to load

  popularSubsCollection = await getCollectionsByHandles({
    handles: ["litter-boxes", "basic-bundle", "perfect-bundle", "super-bundle"],
  });

  const data: pageData = {
    popularSubsCollection,
  };

  return data;
};

export const getStaticProps: GetStaticProps = async (context) => {
  const content = await getBuilderStaticProps("page", context, "/", [
    "large-hero",
  ]);

  const collection = await getPopularSubs(context);
  return {
    revalidate: 30,
    props: {
      ...content.props,
      data: collection,
    },
  };
};

export default function Home(
  props: InferGetStaticPropsType<typeof getStaticProps>
) {
  const { popularSubsCollection }: pageData = props.data;
  const pageData = props.builderPage.data;

  return (
    <>
      <SEO title={pageData.title} description={pageData.description} image="" />
      <div className={styles.homepageWrapper}>
        <BuilderComponent
          content={props.sections["large-hero"]}
          model="large-hero"
          options={{ enrich: true }}
          renderLink={renderLink}
        />
        {pageData.pageOptions && pageData.pageOptions.showBundlesHp && (
          <PopularSubscriptions
            data={HomepageData.popular}
            popularSubsCollection={popularSubsCollection}
          />
        )}
        <BuilderComponent model={"page"} content={props.builderPage} />
      </div>
    </>
  );
}
